// Zone.js
import 'zone.js/dist/zone';

if (APP_MODE == 'development') {
    // Development and test
    Error['stackTraceLimit'] = Infinity;
    require('zone.js/dist/long-stack-trace-zone');
}

// Styles
import './vendor.scss';

// tw Bootstrap
import 'bootstrap';

// INSPINIA Plugins
import 'metismenu';

// Moment TZ
import 'moment-timezone';
